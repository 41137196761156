@charset "UTF-8";
/* Slider */
@import url(../vendor/chosen/chosen.css);
@import url(../vendor/zebra_datepicker/public/css/bootstrap.css);
@import url(../vendor/rangeslider/css/ion.rangeSlider.css);
@import url(../vendor/rangeslider/css/ion.rangeSlider.skinHTML5.css);
@import url(../vendor/loading/loading.css);
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.ps-container {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .ps-container {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-container {
      overflow: auto !important; } }
  .ps-container.ps-active-x > .ps-scrollbar-x-rail,
  .ps-container.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transparent; }
  .ps-container.ps-in-scrolling {
    pointer-events: none; }
    .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #999; }
    .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #999; }
  .ps-container > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: #aaa;
      border-radius: 4px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .ps-container > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 3px;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 8px; }
    .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: #aaa;
      border-radius: 4px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 8px; }
  .ps-container:hover.ps-in-scrolling {
    pointer-events: none; }
    .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #999; }
    .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #999; }
  .ps-container:hover > .ps-scrollbar-x-rail,
  .ps-container:hover > .ps-scrollbar-y-rail {
    opacity: 0.6; }
  .ps-container:hover > .ps-scrollbar-x-rail:hover {
    background-color: #eee;
    opacity: 0.9; }
    .ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: #999; }
  .ps-container:hover > .ps-scrollbar-y-rail:hover {
    background-color: #eee;
    opacity: 0.9; }
    .ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: #999; }
